import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { GroupTypes } from 'utils/constants/groups'
import { getUserSession } from 'utils/functions/user'
import { Nullable } from 'utils/types/common'
import { Company } from 'utils/types/company'
import { SubscriptionPlan, SubscriptionStatus } from 'utils/types/subscriptions'
import { useAuth0Store } from 'utils/auth0-store'

import {
  AppGroup,
  Group,
  InvestorGroup,
  Role,
  Session,
  User,
  UserSession,
} from 'utils/types/user'

export interface AuthState {
  user: Nullable<User>
  userSession: Nullable<UserSession>
  groups: Nullable<{
    [id: string]: AppGroup
  }>
  showTermsOfUse?: boolean
  currentSubstep: string
  googleToken?: string
  accountType?: 'investor' | 'founder'
  isSigningUp?: boolean
  confirmationToken?: string
  betaGroups?: Record<string, boolean>
  redirectUrl?: Nullable<string>
  customLoginData?: Group
}

const initialState: AuthState = {
  user: null,
  userSession: null,
  groups: null,
  currentSubstep: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInCreateSession(
      draftState,
      action: PayloadAction<{
        user: User
        groups: { [id: string]: Group }
        investorGroups: { [id: string]: InvestorGroup }
        accessToken: string
        idToken: string
      }>
    ) {
      const { user, groups, investorGroups, idToken, accessToken } =
        action.payload

      const userSession: UserSession = {
        accessToken,
        idToken,
        currentGroupId: undefined,
        previousGroupId: undefined,
      }

      const newSession = getUserSession({
        user,
        groups,
        userSession,
        investorGroups,
      })

      draftState.user = newSession.user
      draftState.groups = newSession.groups
      draftState.userSession = newSession.userSession

      return draftState
    },
    setAuth0IdToken: (state: AuthState, action: PayloadAction<string>) => {
      state.userSession = {
        ...state.userSession,
        idToken: action.payload,
      }
      return state
    },
    setAuth0AccessToken: (state: AuthState, action: PayloadAction<string>) => {
      state.userSession = {
        ...state.userSession,
        accessToken: action.payload,
      }
      return state
    },
    signOut: () => {
      try {
        useAuth0Store.getState().setAuth0Domain(null)
      } catch {
        // continue regardless of error
      }

      return {
        ...initialState,
      }
    },
    showTermsOfUse: (
      state: AuthState,
      action: PayloadAction<{ show: boolean }>
    ) => {
      state.showTermsOfUse = action.payload.show

      return state
    },
    updateUserSession: (
      state: AuthState,
      action: PayloadAction<
        Session<User> & {
          investorGroups?: { [id: string]: InvestorGroup }
        }
      >
    ) => {
      const { user, userSession, investorGroups } = action.payload
      let { groups } = action.payload

      Object.values<AppGroup>(investorGroups || {}).forEach((investorGroup) => {
        investorGroup.type = GroupTypes.INVESTOR_GROUP
      })
      groups = { ...groups, ...investorGroups }
      const groupId = Object.keys(groups)[0]

      if (!userSession.currentGroupId) {
        userSession.currentGroupId = groupId
      }

      if (!userSession.groupId) {
        userSession.groupId = groupId
      }

      state.user = user
      state.groups = groups
      state.userSession = userSession

      return state
    },

    updateUser: (state: AuthState, action: PayloadAction<Partial<User>>) => {
      state.user = { ...(state.user ?? {}), ...action.payload } as User

      return state
    },
    updateGroup: (
      state: AuthState,
      action: PayloadAction<Partial<AppGroup>>
    ) => {
      if (!action.payload.id) {
        return state
      }
      state.groups = {
        ...state.groups,
        [action.payload.id]: {
          ...state.groups![action.payload.id],
          ...action.payload,
        },
      }

      return state
    },
    setStripeSubscription: (
      draftState: AuthState,
      action: PayloadAction<{
        stripeSubscriptionId: string
        stripePlan: SubscriptionPlan
        stripeStatus: SubscriptionStatus
        stripeNextBillingDate: Date | string
      }>
    ) => {
      const currentGroupId = draftState.userSession?.currentGroupId
      const currentGroup = draftState.groups![currentGroupId!]

      currentGroup.stripeSubscriptionId = action.payload.stripeSubscriptionId
      currentGroup.stripeStatus = action.payload.stripeStatus
      currentGroup.stripePlan = action.payload.stripePlan
      currentGroup.stripeNextBillingDate = String(
        action.payload.stripeNextBillingDate
      )

      return draftState
    },
    setIsSigningUp: (draftState: AuthState, action: PayloadAction<boolean>) => {
      draftState.isSigningUp = action.payload
      return draftState
    },
    setStripeCustomerId: (
      draftState: AuthState,
      action: PayloadAction<string>
    ) => {
      const currentGroupId = draftState.userSession?.currentGroupId
      const currentGroup = draftState.groups![currentGroupId!]
      currentGroup.stripeCustomerId = action.payload
      return draftState
    },
    setCurrentGroupRole: (
      draftState: AuthState,
      action: PayloadAction<Role>
    ) => {
      if (draftState.user) {
        draftState.user.currentGroupRole = action.payload
      }
    },
    setCurrentGroupId: (
      draftState: AuthState,
      action: PayloadAction<string>
    ) => {
      draftState.userSession = {
        ...draftState.userSession!,
        currentGroupId: action.payload,
      }
      return draftState
    },
    switchGroup: (draftState: AuthState, action: PayloadAction<string>) => {
      draftState.userSession = {
        ...draftState.userSession!,
        currentGroupId: action.payload,
        previousGroupId:
          action.payload === draftState.userSession?.currentGroupId
            ? draftState.userSession?.previousGroupId
            : draftState.userSession?.currentGroupId,
      }
      return draftState
    },
    setGroupBeta: (
      draftState: AuthState,
      action: PayloadAction<{ groupId: string; isBeta?: boolean }>
    ) => {
      draftState.betaGroups = {
        ...draftState.betaGroups,
        [action.payload.groupId]: Boolean(action.payload.isBeta),
      }
      return draftState
    },
    setUserGroups: (
      draftState: AuthState,
      action: PayloadAction<(User | Group<Company>)[]>
    ) => {
      const groupsObject = action.payload.reduce((res, group) => {
        res[group.id] = group
        return res
      }, {})
      draftState.groups = groupsObject

      if (
        draftState.userSession?.currentGroupId &&
        !groupsObject[draftState.userSession?.currentGroupId]
      ) {
        // user was removed from current group
        draftState.userSession.currentGroupId = action.payload?.[0]?.id
      }

      return draftState
    },
    addNewUserGroup: (draftState: AuthState, action: PayloadAction<Group>) => {
      draftState.groups = {
        ...(draftState.groups || {}),
        [action.payload.id]: action.payload,
      }

      return draftState
    },
    setCustomLoginData: (
      draftState: AuthState,
      action: PayloadAction<Group>
    ) => {
      draftState.customLoginData = action.payload
      return draftState
    },
  },
})

export const {
  setAuth0IdToken,
  setAuth0AccessToken,
  setCurrentGroupId,
  setCurrentGroupRole,
  setCustomLoginData,
  setGroupBeta,
  setIsSigningUp,
  setStripeCustomerId,
  setStripeSubscription,
  setUserGroups,
  showTermsOfUse,
  signInCreateSession,
  signOut,
  switchGroup,
  updateGroup,
  updateUser,
  updateUserSession,
  addNewUserGroup,
} = authSlice.actions

export const getAuth = (state: { auth: AuthState }) => state.auth

export default authSlice.reducer
