/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react'
import { useQueryClient } from '@tanstack/react-query'
import { signOut } from 'features/authSlice'
import { CustomLogin } from 'features/preferenceSlice'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { setDesktopBadge } from 'utils/functions/electron'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { usePreference } from 'utils/hooks/usePreference'
import useQueryString from 'utils/hooks/useQueryString'
import * as FullStory from 'utils/integrations/fullStory'

const Signout = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [customLoginPreference] = usePreference<CustomLogin>('customLogin')
  const queryClient = useQueryClient()
  const { logout } = useAuth0()
  const redirectTo = useQueryString('redirectTo')

  // useQueryString (used to get the email from the url when redirected back to SignInAuth0.tsx) parses `+` as a white space,
  // but we potentially have it as part of the email to sign up, so we need to manually encode it before sending it to Auth0
  const parsedRedirectTo = redirectTo?.replace('+', '%2B')

  useEffect(() => {
    ;(async () => {
      FullStory.shutdown()

      const signInUrl = customLoginPreference?.hasSignedInOnCustomLogin
        ? `/sign-in/${customLoginPreference.customLoginHandle}`
        : '/sign-in'

      try {
        await logout({
          // doing a GET request instead of the deault navigate to the url, so that the users don't get a timeout
          // when we switch domains
          openUrl: (url) => {
            fetch(url, { method: 'GET', credentials: 'include' })
          },
        })
      } catch {
        // if logout fails, it's probably the old auth0 domain
        // continue with our signout
      }

      setDesktopBadge()
      queryClient.clear()
      dispatch(signOut())
      history.replace(parsedRedirectTo || signInUrl)
    })()
  }, [])

  return null
}

export default Signout
